<template>
  <div class="project-settings-wrap">
    <!-- <side-bar/> -->
    <section class="project-content">
      <!-- <page-header></page-header> -->
      <router-view />
    </section>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar'
// import pageHeader from '@/components/pageHeader'
export default {
  components: {
    SideBar
  }
}
</script>

<style lang="scss" scoped>
  .project-settings-wrap {
    width: 100%;
    min-height: 100%;
    // padding: 30px 30px;
    // display: flex;
    // .project-content{
    //   width: 100%;
    //   margin-left: 30px;
    // }
  }
</style>
